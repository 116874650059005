import { useMatomo } from '@datapunt/matomo-tracker-react';
import {
  Badge,
  Button,
  Checkbox,
  ConfigProvider,
  Dropdown,
  Slider,
  Typography
} from 'antd';
import { isArray } from 'lodash';
import dynamic from 'next/dynamic';
import {
  DotsThreeOutlineVertical,
  MinusCircle,
  SketchLogo
} from 'phosphor-react';
import React, { Fragment } from 'react';
import {
  COLOR_KEYS,
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  MEMBERS_ONLY,
  ROUTES,
  TRACK_VALUE
} from '../../../../common/constants';
import { gaEvent } from '../../../../lib/gtag';
import { useApp } from '../../../../providers/AppContext';
import CustomAnchor from '../../../CustomAnchor';

const BlurHashImage = dynamic(() => import('../../../BlurHashImage'), {
  loading: () => null,
  ssr: false
});

const { Text, Paragraph } = Typography;
function VideoCard({
  src,
  videoId,
  blurhash,
  defaultValue,
  time,
  category,
  collection,
  title,
  date,
  videoList,
  tagBackground = 'bg-tranperant',
  tagTextColor = 'text-n-900',
  tags,
  videoSelect = false,
  videoSelectCheckbox,
  videoDescription,
  videoDelete,
  handleDelete,
  isVideoSelected,
  dropdownItems,
  href = '#',
  categoryHref = '#',
  handleDropdownItemClick,
  isPremium = false,
  instanceData,
  instanceId,
  instanceType
}) {
  const {
    state: { placeholderImage, currentUser }
  } = useApp();
  const viewDelete = videoSelect && videoDelete;
  const { trackEvent } = useMatomo();

  const handleVideoAnalytics = (matomoCategory, action, statusSuccess) => {
    trackEvent({
      category: matomoCategory,
      action,
      name: JSON.stringify({
        action,
        email: currentUser?.email ?? '',
        instanceData,
        instanceId,
        instanceType
      }),
      value: statusSuccess
    });
    gaEvent({
      category: matomoCategory,
      action,
      name: JSON.stringify({
        action,
        userId: currentUser?.id ?? '',
        instanceData,
        instanceId,
        instanceType
      }),
      value: statusSuccess
    });
  };

  const handleTagAnalytics = (matomoCategory, action, statusSuccess) => {
    trackEvent({
      category: matomoCategory,
      action,
      name: JSON.stringify({
        action,
        email: currentUser?.email ?? '',
        tags: instanceData?.tags
      }),
      value: statusSuccess
    });
    gaEvent({
      category: matomoCategory,
      action,
      name: JSON.stringify({
        action,
        userId: currentUser?.id ?? '',
        tags: instanceData?.tags
      }),
      value: statusSuccess
    });
  };

  const handleTopicsAnalytics = (matomoCategory, action, statusSuccess) => {
    trackEvent({
      category: matomoCategory,
      action,
      name: JSON.stringify({
        action,
        email: currentUser?.email ?? '',
        topics: instanceData?.topics
      }),
      value: statusSuccess
    });
    gaEvent({
      category: matomoCategory,
      action,
      name: JSON.stringify({
        action,
        userId: currentUser?.id ?? '',
        topics: instanceData?.topics
      }),
      value: statusSuccess
    });
  };

  return (
    <div
      className={`video-card ${videoList && 'video-list'} ${
        viewDelete && 'video-list-delete'
      }`}
    >
      <div
        className="video-image relative bg-n-500"
        onClick={() =>
          handleVideoAnalytics(
            MATOMO_CATEGORY.VIEW_DETAIL,
            MATOMO_ACTION.VIEW_VIDEO,
            TRACK_VALUE.SUCCESS
          )
        }
      >
        <CustomAnchor href={href}>
          <BlurHashImage
            src={src || placeholderImage}
            blurhash={blurhash}
            fill
            alt={title}
            className="image-thumbnail cover"
          />
        </CustomAnchor>
        {time ? <div className="timestemp">{time} </div> : null}
        {defaultValue ? (
          <ConfigProvider
            theme={{
              components: {
                Slider: {
                  railSize: 4,
                  borderRadiusXS: 12,
                  colorBgElevated: ' #FFFFFF',
                  dotSize: 8,
                  handleSize: 16,
                  handleSizeHover: 16,
                  colorPrimaryBorder: '#FE4819',
                  colorBorderSecondary: '#B0ADAB',
                  colorBgContainer: '#B0ADAB',
                  colorTextDescription: '#FFFFFF',
                  colorTextDisabled: '#FFFFFF',
                  colorFillContentHover: '#FFFFFF',
                  colorFillSecondary: '#ffff',
                  colorFillTertiary: '#B0ADAB',
                  colorPrimaryBorderHover: '#3D3B38',
                  handleLineWidthHover: 0,
                  handleLineWidth: 0
                }
              }
            }}
          >
            <Slider defaultValue={defaultValue} />
          </ConfigProvider>
        ) : null}
      </div>

      <div className="vc-description">
        {category ? (
          <a
            href={categoryHref}
            className="category-badge"
            onClick={() =>
              handleTopicsAnalytics(
                MATOMO_CATEGORY.TOPICS,
                MATOMO_ACTION.TOPICS_CARD_CLICKED,
                TRACK_VALUE.SUCCESS
              )
            }
          >
            <Badge
              className={`badge-xs text-black-90 ${
                COLOR_KEYS[tagBackground]?.backgroundClass ?? ''
              } ${tagTextColor}`}
            >
              {category}
            </Badge>
          </a>
        ) : null}

        {isPremium ? (
          <Badge className="badge-md text-white-90 bg-n-900 premium-tag ml-4">
            <SketchLogo size={18} />
            <span className="text-label-xs">{MEMBERS_ONLY}</span>
          </Badge>
        ) : null}

        {collection ? (
          <Text className="text-small-kicker text-black-80 block">
            {collection}
          </Text>
        ) : null}

        {title ? (
          <CustomAnchor href={href} className="text-md-bold block vc-title">
            {title}
          </CustomAnchor>
        ) : null}
        {videoList && videoDescription ? (
          <Paragraph
            className="text-small text-black-90 vc-paragraph"
            ellipsis={{ rows: 2 }}
          >
            {videoDescription}
          </Paragraph>
        ) : null}
        {tags && !!tags?.length ? (
          <div className="tag-list ">
            {tags?.map(({ name, slug }, index) => (
              <Fragment key={slug}>
                <a
                  className="text-small-bold text-black-80"
                  href={`${ROUTES.TAGS}/${slug}`}
                  onClick={() =>
                    handleTagAnalytics(
                      MATOMO_CATEGORY.TAGS,
                      MATOMO_ACTION.TAGS_CARD_CLICKED,
                      TRACK_VALUE.SUCCESS
                    )
                  }
                >
                  {name}
                </a>
                {index !== tags?.length - 1 && ', '}
              </Fragment>
            ))}
          </div>
        ) : null}

        {date ? (
          <Text className="text-small text-black-90 block ">{date}</Text>
        ) : null}
      </div>
      {!viewDelete && videoSelect && (
        <div className="video-select">
          <Checkbox checked={isVideoSelected} onChange={videoSelectCheckbox} />
        </div>
      )}
      {viewDelete && (
        <Button
          className="hbg-transparent text-red hc-red"
          type="text"
          onClick={() => handleDelete(videoId)}
        >
          <MinusCircle size={26} />
        </Button>
      )}

      {isArray(dropdownItems) && dropdownItems?.length > 0 && (
        <Dropdown
          menu={{
            items: dropdownItems,
            onClick: ({ key }) => handleDropdownItemClick(key, videoId)
          }}
          trigger={['click']}
          placement="bottomRight"
        >
          <Button
            className="p-0 bg-transparent"
            type="text"
            onClick={(e) => e.preventDefault()}
          >
            <DotsThreeOutlineVertical size={24} />
          </Button>
        </Dropdown>
      )}
    </div>
  );
}
export default VideoCard;

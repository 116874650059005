import { Col, Empty, Row } from 'antd';
import {
  getVideoProgressInPercentage,
  secondsToMinute
} from '../../../../common/utils';
import VideoListLoading from '../../../skeleton/VideoListLoading';
import SectionHeader from '../../SectionHeader/SectionHeader';
import VideoCard from './VideoCard';

function VideoList({
  sectionHeading,
  headerLink,
  headerDescription,
  data = [],
  videoSelect,
  videoSelectCheckbox,
  selectedVideoIds,
  videoDelete,
  handleDelete,
  separator,
  showVideoProgressBar,
  videosWatchTimes,
  dropdownItems,
  handleDropdownItemClick
}) {
  return (
    <section className={`video-list-grid ${!separator ? 'separator-off' : ''}`}>
      {sectionHeading && (
        <div className="container">
          <SectionHeader
            heading={sectionHeading}
            headerLink={headerLink}
            headerDescription={headerDescription}
          />
        </div>
      )}

      <div className="container-rnp">
        {!data?.length && (
          <Empty
            className="text-md-bold"
            image="/images/no-videos.svg"
            description="No Videos!"
          />
        )}
        <Row>
          {data?.map((item) => {
            return (
              <Col
                key={item?.id}
                xs={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <VideoCard
                  dropdownItems={dropdownItems}
                  videoList
                  videoId={item?.id}
                  videoDelete={videoDelete}
                  videoSelect={videoSelect}
                  src={item?.src}
                  videoThumbnail={item?.videoThumbnail}
                  blurhash={item?.blurhash}
                  defaultValue={item?.defaultValue}
                  time={secondsToMinute(item?.time)}
                  category={item?.category}
                  categoryHref={item?.categoryHref}
                  collection={item?.collection}
                  title={item?.title}
                  date={item?.date}
                  tags={item?.tags}
                  tagBackground={item?.tagBackground}
                  tagTextColor={item?.tagTextColor}
                  videoDescription={item?.videoDescription}
                  handleDelete={handleDelete}
                  href={item?.href}
                  handleDropdownItemClick={handleDropdownItemClick}
                  {...(videoSelect && {
                    videoSelectCheckbox: (e) => videoSelectCheckbox(e, item),
                    isVideoSelected: selectedVideoIds?.includes(item?.id)
                  })}
                  {...(showVideoProgressBar && {
                    defaultValue: getVideoProgressInPercentage(
                      videosWatchTimes?.[item?.id],
                      item?.time
                    )
                  })}
                  isPremium={item?.isPremium}
                  instanceData={item?.instanceData}
                  instanceId={item?.instanceId}
                  instanceType={item?.instanceType}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    </section>
  );
}

VideoList.Loading = function Loading() {
  return <VideoListLoading />;
};

export default VideoList;

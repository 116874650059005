import { Typography } from 'antd';
import { CaretRight } from 'phosphor-react';
import CustomAnchor from '../../CustomAnchor';

const { Paragraph } = Typography;
function SectionHeader({ heading, headerLink, headerDescription }) {
  return (
    (heading || headerLink || headerDescription) && (
      <div className="section-header">
        <div className="sh-top mb-4">
          {heading ? (
            <div className="sh-title text-black-90">{heading}</div>
          ) : null}
          <span className="flex-1" />
          {headerLink ? (
            <CustomAnchor
              className="d-flex align-center text-n-900 button-m"
              href={headerLink}
            >
              ALL <CaretRight size={16} />
            </CustomAnchor>
          ) : null}
        </div>
        {headerDescription ? (
          <Paragraph
            className="text-small text-white-90 vc-paragraph mb-16"
            ellipsis={{ rows: 2 }}
          >
            {headerDescription}
          </Paragraph>
        ) : null}
      </div>
    )
  );
}
export default SectionHeader;

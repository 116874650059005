import { Spin } from 'antd';
import React from 'react';

function Spinner({ className = '' }) {
  return (
    <div className={`d-flex align-center justify-center ${className}`}>
      <Spin />
    </div>
  );
}

export default Spinner;

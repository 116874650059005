import React, { forwardRef, useEffect, useRef } from 'react';

// eslint-disable-next-line react/display-name
const InView = forwardRef(({ onChange, rootMargin = '0px' }, rootRef) => {
  const ref = useRef();

  const onChangeRef = useRef();
  onChangeRef.current = onChange;

  useEffect(() => {
    const node = ref.current;
    // eslint-disable-next-line no-undef
    const observer = new IntersectionObserver(
      (entries) => {
        const { isIntersecting } = entries[0];
        onChangeRef.current({ inView: isIntersecting });
        if (isIntersecting) {
          observer.unobserve(node);
        }
      },
      {
        root: rootRef?.current,
        rootMargin
      }
    );

    if (node) {
      observer.observe(node);
    }

    return () => {
      if (node) observer.unobserve(node);
    };
  }, [onChangeRef, rootRef, rootMargin]);

  return <div style={{ minHeight: 1 }} ref={ref} />;
});

export default InView;
